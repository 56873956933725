import React from 'react'
// import Img from 'gatsby-image'
import { graphql } from "gatsby"
import get from 'lodash/get'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
// import Stack from '../components/Stack'
import Header from '../components/Header'
import { pages } from '../utils'
import styled from 'styled-components'


const Container = styled.div`
  max-width: 932px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
`

class Price extends React.Component
{
	render()
	{
		const siteUrl = get(this, 'props.data.site.siteMetadata.siteUrl')
		return (
      <Layout location={this.props.location} active={pages.price}>
        <div>
          <SEO title="料金" url={`${siteUrl}/price`} />
          <Header
            title="ご利用料金"
            description="中小企業にIT・クラウドの導入を推進するためのご利用料金をお手頃価格でご用意しております。貴社のIT顧問・IT教育に尽力いたします。"
            color="#3384a0"
          />
          <div className="container">
            <div className="pricing-table-horizontal">
              <div className="plan">
                <div className="plan-left-information">
                  <h2>ベーシックプラン</h2>
                  <p>
                    ベーシックプランでは、ITに関するご質問・アドバイスを「チャットワーク」を通じて行います。
                    お客様のほうでご担当者をたてていただき、やりとりを行います。
                    質問は何回でもOKです。プログラム、クラウド、パソコンのご相談を承ります。
                  </p>
                  <p className="plan-price">¥50,000 / 月</p>
                </div>
                <div className="plan-right-information">
                  <p>
                    <strong>✅</strong>
                    <span>チャットワーク</span>
                  </p>
                  <p>
                    <strong>✅</strong>
                    <span>ご担当者１名</span>
                  </p>
                  <p>
                    <strong>❌</strong>
                    <span>電話サポート</span>
                  </p>
                  <p>
                    <strong>❌</strong>
                    <span>訪問サポート</span>
                  </p>
                  <div className="plan-order">
                    <a href="/blog/basic-plan" className="order-a-plan">
                      詳しくはこちら
                    </a>
                  </div>
                </div>
              </div>
              <div className="plan featured">
                {/* <span className="popular-badge">おすすめ</span> */}
                <div className="plan-left-information">
                  <h2>RPA人材　育成プラン</h2>
                  <p>
                    RPAは、人間ではなくロボットが作業するため、24時間365日休むことなく稼働でき、処理スピードも早いため、業務効率化や人件費の削減につながります。
                    <br />
                    その手法を一から学び、社内生産性向上、労働力不足問題解消、社員のストレスの解消を行うことを目的といたします。
                  </p>
                  <p className="plan-price">¥500,000 / 月</p>
                </div>
                <div className="plan-right-information">
                  <p>
                    <strong>✅</strong>
                    <span>チャットワーク</span>
                  </p>
                  <p>
                    <strong>✅</strong>
                    <span>Zoom対応</span>
                  </p>
                  <p>
                    <strong>✅</strong>
                    <span>複数名</span>
                  </p>
                  <p>
                    <strong>✅</strong>
                    <span>定期的な技術教育</span>
                  </p>

                  <div className="plan-order">
                    <a href="/blog/rpa" className="order-a-plan">
                      詳しくはこちら
                    </a>
                  </div>
                </div>
              </div>

              <div className="plan featured">
                <span className="popular-badge">おすすめ</span>
                <div className="plan-left-information">
                  <h2>Powerプラットフォーム導入プラン</h2>
                  <p>
                    社内のITに関する問題点を、社員様と集中的かつ徹底的に洗い出し、根本から改善するためのスキルを身につけるための教育を行います。
                    複数名の社員様が対象となります。
                    <br />
                    特に、クラウド基幹システムであるMicrosoft「PowerApps」の導入支援、開発支援、技術指導を行うことにより、将来の人不足解消や、IT担当者への過度な依存を平準化し、長く使えるシステム構築を行います。
                    こちら、お客様のケースに応じて、プランをオリジナルで設計してまいります。
                    ※Office365との親和性が高く、非常に拡張性の高いクラウドシステムとなります。
                  </p>
                  <p className="plan-price">¥500,000〜 / 月</p>
                </div>
                <div className="plan-right-information">
                  <p>
                    <strong>✅</strong>
                    <span>全てのサポート</span>
                  </p>
                  <p>
                    <strong>✅</strong>
                    <span>完全な技術顧問</span>
                  </p>
                  <p>
                    <strong>✅</strong>
                    <span>定期訪問教育</span>
                  </p>
                  <p>
                    <strong>✅</strong>
                    <span>PowerApps導入</span>
                  </p>

                  <div className="plan-order">
                    <a href="/blog/premium-plan" className="order-a-plan">
                      詳しくはこちら
                    </a>
                  </div>
                </div>
              </div>

              <div className="plan featured">
                <span className="popular-badge">おすすめ</span>
                <div className="plan-left-information">
                  <h2>Kintone導入プラン</h2>
                  <p>
                    社内のITに関する問題点を、社員様と集中的かつ徹底的に洗い出し、根本から改善するためのスキルを身につけるための教育を行います。
                    複数名の社員様が対象となります。
                    <br />
                    特に、クラウド基幹システムである「Kintone」の導入支援、開発支援、技術指導を行うことにより、将来の人不足解消や、IT担当者への過度な依存を平準化し、長く使えるシステム構築を行います。
                    こちら、お客様のケースに応じて、プランをオリジナルで設計してまいります。
                    ※Kintone導入の支援・開発については、プレミアムプランのみとなります。
                  </p>
                  <p className="plan-price">¥400,000〜 / 月</p>
                </div>
                <div className="plan-right-information">
                  <p>
                    <strong>✅</strong>
                    <span>全てのサポート</span>
                  </p>
                  <p>
                    <strong>✅</strong>
                    <span>完全な技術顧問</span>
                  </p>
                  <p>
                    <strong>✅</strong>
                    <span>定期訪問教育</span>
                  </p>
                  <p>
                    <strong>✅</strong>
                    <span>Kintone導入</span>
                  </p>

                  <div className="plan-order">
                    <a href="/blog/premium-plan" className="order-a-plan">
                      詳しくはこちら
                    </a>
                  </div>
                </div>
              </div>

              <div className="plan">
                <div className="plan-left-information">
                  <h2>呉島道場　受講プラン</h2>
                  <p>
                    さらにITスキルアップを目指すため、WEBやプログラミングの学習を定期的に実施いたします。
                    現役ITエンジニアが教える最新技術などを学ぶことができます。
                    また、他の受講生との交流を通して、共に学ぶことによるスキルアップが見込めます。
                  </p>
                  <p className="plan-price">コース受講料金 / 月</p>
                </div>
                <div className="plan-right-information">
                  <p>
                    <strong>✅</strong>
                    <span>コースごとの受講</span>
                  </p>
                  <p>
                    <strong>✅</strong>
                    <span>社内問題対策</span>
                  </p>
                  <p>
                    <strong>✅</strong>
                    <span>電話サポート</span>
                  </p>

                  <div className="plan-order">
                    <a href="/blog/kuredojo-plan" className="order-a-plan">
                      詳しくはこちら
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
	}
}

export const queryPrice = graphql`
  query QueryPrice {
   	site {
     	siteMetadata {
       	title
      	siteUrl
     	}
   	}
   	aboutImage: imageSharp(fluid: {originalName: { regex: "/about-image.jpg/" } }) {
   	   sizes(maxWidth: 960) {
   	      ...GatsbyImageSharpSizes_tracedSVG
   	   }
		 }
		 
		testImage: imageSharp(fluid: {originalName: { regex: "/test-img.jpg/" } }) {
   	   sizes(maxWidth: 960) {
   	      ...GatsbyImageSharpSizes_tracedSVG
   	   }
			}
			
		lastpassImage: imageSharp(fluid: {originalName: { regex: "/lastpass.jpg/" } }) {
			sizes(maxWidth: 960) {
				...GatsbyImageSharpSizes_tracedSVG
			}
		}
		gsuiteImage: imageSharp(fluid: {originalName: { regex: "/gsuite.jpg/" } }) {
			sizes(maxWidth: 960) {
				...GatsbyImageSharpSizes_tracedSVG
			}
		}
		chatworkImage: imageSharp(fluid: {originalName: { regex: "/chatwork.jpg/" } }) {
			sizes(maxWidth: 960) {
				...GatsbyImageSharpSizes_tracedSVG
			}
		}
		seoImage: imageSharp(fluid: {originalName: { regex: "/seo.jpeg/" } }) {
			sizes(maxWidth: 960) {
				...GatsbyImageSharpSizes_tracedSVG
			}
		}
		excelImage: imageSharp(fluid: {originalName: { regex: "/excel.jpg/" } }) {
			sizes(maxWidth: 960) {
				...GatsbyImageSharpSizes_tracedSVG
			}
		}
		iphoneImage: imageSharp(fluid: {originalName: { regex: "/iphone.jpeg/" } }) {
			sizes(maxWidth: 960) {
				...GatsbyImageSharpSizes_tracedSVG
			}
		}
  }
`

export default Price
